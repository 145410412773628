// vue
import { ref, computed, reactive, watch, onMounted } from 'vue';
import moment from 'moment';

// services
import BotsService from'@services/bots';
import StatsService from '@services/statsService';
import LocalStorage from '@services/ts/localStorage';

// shared
import { CRUD_PAGER_FACTORY } from '@shared/factories';

// i18n
import { useI18n } from 'vue-i18n';

// router
import { useRoute, useRouter } from 'vue-router';

// UI
import { useMessage, useNotification } from 'naive-ui';

// store
import { useGl } from '@store/ts/gl';
import { useEnv } from '@/store/env';
import { useRefs } from '@store/ts/refs';
import { useStats } from '@store/stats';

export default function (props, context) {
// store
    const gl = useGl();
    const env = useEnv();
    const refs = useRefs();
    const stats = useStats();

    // naive-ui
    
    const notification = useNotification();

    const route = useRoute();
    const router = useRouter();

    // i18n
    const { t } = useI18n();

    // vars
    const { _ } = window;
    const botShortInfo = ref();
    const init = ref(false);
    const action = ref();
    const totalUpdateLoading = ref(false);

    const confirmLabel = ref();
    const declineMessage = ref();
    const genConfCheck = ref(false);
    const cancelOrders = ref(false);
    const showConfirmation = ref(false);
    const markLastAsCanceled = ref(false);
    const showConfirmationCheck = ref(false);
    const stopBotAfterOperation = ref(false);
    const RE_SET_FIX_ORDER_FACTORY = id => ({
        rate_to_finish_cycle: 0,
        rate_to_get_profit_hint: '',
        rate_to_get_profit: '',
        new_rate_to_finish_cycle: '',
        new_rate_to_get_profit_hint: '0%',
        new_rate_to_finish_cycle_suggest: 'Please, calculate',
    
        stop_bot_after_fix_completed: false,
    });
    const reSetFixOrder = ref(RE_SET_FIX_ORDER_FACTORY());

    const loadings = reactive({
        clearCycles: false,
    });
    
    const filters = reactive(CRUD_PAGER_FACTORY({
        bots: [+route.params.id],
        period: moment().subtract(60, 'days').format('YYYY-MM-DD') + ' ' + moment().add(7, 'days').format('YYYY-MM-DD'),
        statuses: [-1],
    }, {
        perPage: window.isValidJSON(LocalStorage.getItem('__filters_cycles_table'))
            ? JSON.parse(LocalStorage.getItem('__filters_cycles_table'))
            : 10,
    }));
    const filtersChanged = computed(() => {
        return filters.filters.statuses[0] !== -1;
    });
    const resetFilters = () => {
        filters.filters.statuses = [ -1 ];
            
        cyclesGet();
    };
    const refFilters = computed(() => [
        {
            mode: 'radio_group',
            title: stats.localization['trade_statistics_filters_statuses'],
            refs: stats.cycleFilters,
            value: filters.filters.statuses[0],
            filter: 'filters.statuses',
        }, {
            type: 'render',
            mode: 'date_picker',
            value: periods.value,
            filter: 'filters.period',
        },
    ]);

    const optionsStatuses = computed(() => {
        const arr = stats.cycleFilters.map(({ id, title }) => ({
            value: id,
            label: title,
        })).sort(({ value }, { value: value2 }) => value - value2);

        arr.unshift({
            value: -1,
            label: stats.localization['trade_statistics_filters_bots_all_button'],
        });

        return arr;
    });

    const ordersFilters = reactive(CRUD_PAGER_FACTORY({
        bots: [+route.params.id],
    }, {
        perPage: 50,
    }));

    const periods = computed(() => {
        const arr = filters.filters.period.split(' ');

        return [new Date(arr[0]).getTime(), new Date(arr[1]).getTime()];
    });

    const buttons = computed(() => [
        {
            show: true,
            loading: false,
            label: stats.localization['trade_statistics_management_start_button'],
            fn() {
                canDoAction('start');
            },
        }, {
            show: true,
            loading: false,
            label: stats.localization['trade_statistics_management_restart_button'],
            fn() {
                canDoAction('restart');
            },
        }, {
            show: true,
            loading: false,
            label: stats.localization['trade_statistics_management_stop_button'],
            fn() {
                canDoAction('stop');
            },
        }, {
            show: !botShortInfo.value?.simulate,
            loading: false,
            label: stats.localization['trade_statistics_management_pause_button'],
            fn() {
                canDoAction('pause');
            },
        }, {
            show: true,
            loading: false,
            label: stats.localization['trade_statistics_management_edit_button'],
            fn() {
                router.push({ name: 'bots.edit', params: { id: route.params.id } });
            },
        }, {
            show: !botShortInfo.value?.simulate,
            loading: false,
            label: stats.localization['trade_statistics_management_cancelorders_button'],
            fn() {
                cancelOrders.value = true;
            },
        },{
            show: !botShortInfo.value?.simulate,
            loading: false,
            label: stats.localization['trade_statistics_management_markcyclecanceled_button'],
            fn() {
                markLastAsCanceled.value = true;
            },
        }, {
            show: botShortInfo.value?.simulate,
            loading: loadings.clearCycles,
            label: stats.localization['trade_statistics_bots_table_actions_clear'],
            fn() {
                clearCycles(route.params.id);
            },
        }, {
            show: true,
            loading: loadings.clearCycles,
            label: stats.localization['trade_statistics_bots_table_mass_force_stop'],
            fn() {
                canDoAction('force_stop');
            },
        },
    ]);

    const dashProfit = computed(() => (
        {
            title: stats.localization['trade_statistics_total_profit'],
            data: stats.cyclesDataTable?.revenue_stats,
            ciclos: stats.cyclesDataTable?.revenue_stats?.cycles.completed,
            stats: !stats.cyclesDataTable?.revenue_stats ? [] : (Object.entries(stats.cyclesDataTable?.revenue_stats.coins).map(([key, value]) => [...value, key]))
                .sort(([ , , a], [ , , b]) => {
                    if (a < b)
                        return -1;
                    if (a > b)
                        return 1;
                    return;
                }),
        }),
    );

    const columns = computed(() => {
        if (stats.cyclesDataTable?.columns) {
            const arr = [...stats.cyclesDataTable.columns].filter(({ title }) => title);
            return arr;
        } else return [];
    });

    const columnsOrdersTable = computed(() => {
        if (stats.cyclesDataTable?.columns) {
            const arr = [...stats.ordersDataTable.columns].filter(({ title }) => title);
            return arr;
        } else return [];
    });

    watch(() => refs.lang, async () => {
        // await getData();
    });

    watch([
        () => stats.currentCycle,
        () => stats.forAllCycles,
    ], () => {
        if (stats.currentCycle) {
            resetOrdersFilters();
            ordersGet();
        }
    });

    const resetOrdersFilters = () => {
        ordersFilters.page = 1;
        ordersFilters.perPage = 50;
    };

    const getShortInfo = async (showLoading = true) => {
        if (showLoading)
            gl.showLoading = true;

        try {
            botShortInfo.value = ( await StatsService.getShortInfo(route.params.id) ).data;
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        if (showLoading)
            gl.showLoading = false;
    };

    const changeFilter = ([ key, value ], update = false) => {
        const canUpdate = key === 'query' && !value && stats.cyclesDataTable.pager.query;

        if (key !== 'page') {
            _.set(filters, 'page', 1);
        }

        if (key === 'perPage') {
            LocalStorage.setItem('__filters_cycles_table', value);
        }

        _.set(filters, key, value !== null && value !== undefined ? value : [ -1 ]);
            
        if (update || canUpdate)
            cyclesGet();
    };

    const changeFilter2 = ([ key, value ], update = false) => {
        const canUpdate = key === 'query' && !value && stats.cyclesDataTable.pager.query;

        if (key !== 'page') {
            _.set(ordersFilters, 'page', 1);
        }

        _.set(ordersFilters, key, value !== null && value !== undefined ? value : [ -1 ]);
            
        if (update || canUpdate)
            ordersGet();
    };

    const changeOrdersFilters = ([ key, value ], update = true) => {
        if (key === 'perPage') {
            _.set(ordersFilters, 'page', 1);
        };

        _.set(ordersFilters, key, value !== null && value !== undefined ? value : [ -1 ]);
            
        if (update)
            ordersGet();
    };

    const canDoAction = async curAction => {
        gl.showLoading = true;

        action.value = curAction;
        confirmLabel.value = null;
        declineMessage.value = null;

        if (curAction === 'start') {
            try {
                const result = await BotsService.canDoAction({
                    id: route.params.id,
                    action: curAction,
                });

                if (result) {
                    if (result.data.confirm_label && result.data.decline_message) {
                        showConfirmationCheck.value = true;

                        confirmLabel.value = result.data.confirm_label;
                        declineMessage.value = result.data.decline_message;
                    } else {
                        showConfirmation.value = true;
                    }

                    if (result?.postMessages) {
                        result.postMessages.forEach(el => {
                            notification[el.success ? 'success' : 'error']({
                                content: el.msg,
                                duration: 2500,
                                keepAliveOnHover: true,
                            });
                        });
                    }
                }
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };
        }  else {
            showConfirmation.value = true;
        }

        gl.showLoading = false;
    };

    const onGeneralConfirmClicked = async curAction => {
        gl.showLoading = true;

        try {
            let result;

            if (curAction === 'delete') {
                result = await BotsService.delete({
                    ids: [route.params.id],
                });
            } else {
                result = await BotsService.action({
                    id: route.params.id,
                    action: curAction,
                });
            }
                
            if (result) {
                console.log('result', result);

                // show messages
                if (result.postMessages)
                    result.postMessages.forEach(el => {
                        gl.showNotification({
                            type: el.success ? 'success' : 'error',
                            msg: el.msg,
                        });
                    });

                // if (data.data.status) {
                //     return void emit('getData');
                // }
                await getAll(false);
            }
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        gl.showLoading = false;
    };

    const updateDate = $event => {
        if ($event) 
            filters.filters.period = `${new Date($event[0]).toISOString().split('T')[0]} ${new Date($event[1]).toISOString().split('T')[0]}`;
    };

    const onCancelOrders = async () => {
        gl.showLoading = true;

        try {
            let prepare;

            prepare = await StatsService.cancelOrders({
                id: route.params.id,
                stop_bot_after_operation: stopBotAfterOperation.value,
            });
                
            if (prepare) {
                // show messages
                if (!prepare.data.status) {
                    prepare.data.errors.forEach(({ msg }) => {
                        notification.error({
                            content: msg,
                            duration: 2500,
                            keepAliveOnHover: true,
                        });
                    });

                    setTimeout(() => {
                        cancelOrders.value = true;
                    }, 0);
                        
                } else {
                    cancelOrders.value = false;
                    return void getAll();
                }
            }
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        gl.showLoading = false; 
    };

    const doMarkLastAsCanceled = async () => {
        gl.showLoading = true;

        try {
            const prepare = await StatsService.markLastAsCanceled(route.params.id);

            // show messages
            prepare.postMessages.forEach(el => {
                notification[el.success ? 'success' : 'error']({
                    content: el.msg,
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            });
            
            if (prepare.data.status) {
                markLastAsCanceled.value = false;
                cyclesGet();
                getShortInfo();

                return;
            }
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        gl.showLoading = false;
    };

    const cyclesGet = async (showLoading = true, resetOrdersDataTable = true) => {
        if (showLoading)
            gl.showLoading = true;

        if (resetOrdersDataTable)
            stats.ordersDataTable = null;

        try {
            const prepare = await StatsService.cyclesGet({
                pager: filters,
            });
            if (stats.cyclesDataTable) {
                Object.keys(prepare.data).forEach(key => {
                    if (!['stats', 'columns'].includes(key)) {
                        stats.cyclesDataTable[key] = prepare.data[key];
                    }
                });
            } else {
                stats.cyclesDataTable = prepare.data;
            }

            stats.cyclesDataTable.records.push({
                type: 'footer',
                ...stats.cyclesDataTable.totals,
            });
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        if (showLoading)
            gl.showLoading = false;
    };

    const ordersGet = async () => {
        gl.showLoading = true;

        ordersFilters.filters.cycles = [ stats.forAllCycles ? -1 : stats.currentCycle];
            
        try {
            const prepare = await StatsService.ordersGet({
                pager: ordersFilters,
            });
            if (stats.ordersDataTable) {
                Object.keys(prepare.data).forEach(key => {
                    if (!['columns'].includes(key)) {
                        stats.ordersDataTable[key] = prepare.data[key];
                    }
                });
            } else {
                stats.ordersDataTable = prepare.data;
            }

            setTimeout(() => {
                const el = document.querySelector('#cycles-orders-table');
                if (el) el.scrollIntoView({ block: 'start', behavior: 'smooth' });
            });
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        gl.showLoading = false;
    };

    const getData = async () => {
        try {
            if (!stats.refs)
                stats.refs = await StatsService.getRefs();
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };
    };

    const currency = icon => refs.currencies.find(el => el.id === icon);
    const exchange = exchange => refs.exchanges.find(el => el.id === exchange);

    const getAll = async (showLoading = true) => {
        if (showLoading)
            gl.showLoading = true;

        await Promise.allSettled([
            getData(),
            getShortInfo(false),
            cyclesGet(false),
        ]);

        stats.currentCycle = null;

        if(showLoading)
            gl.showLoading = false;
    };

    const totalUpdate = async () => {
        totalUpdateLoading.value = true;

        await Promise.allSettled([
            getData(),
            getShortInfo(false),
            cyclesGet(false, false),
        ]);

        totalUpdateLoading.value = false;
    };

    const sortColumn = $event => {
        _.set(
            filters,
            'sort_column',
            $event.columnKey !== undefined && $event.order !== undefined
                ? $event.columnKey
                : '');

        _.set(
            filters,
            'sort_dir',
            $event.order !== undefined
                ? $event.order
                : '');

        cyclesGet();
    };

    const downloadData = async format => {
        gl.showLoading = true;

        try {
            const { redirectTo } = ( await StatsService.cyclesGet({
                format,
                pager: filters,
            }) ).data;

            window.location.href = redirectTo;
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        gl.showLoading = false;
    };

    const downloadData2 = async format => {
        gl.showLoading = true;

        try {
            const { redirectTo } = ( await StatsService.ordersGet({
                format,
                pager: ordersFilters,
            }) ).data;

            window.location.href = redirectTo;
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        gl.showLoading = false;
    };

    const doSomething = ([ action, value ]) => {
        const fns = {
            setCurrentCycle: () => {
                stats.currentCycle = value;
            },
        };

        fns[action]();
    };

    const clearCycles = async id => {
        loadings.clearCycles = true;

        try {
            const result = await StatsService.clearCycles(id);

            // show messages
            result.postMessages.forEach(el => {
                notification[el.success ? 'success' : 'error']({
                    content: el.msg,
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            });
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        loadings.clearCycles = false;
    };

    onMounted(async () => {
        await getAll();

        init.value = true;
    });

    return {
        gl,
        env,
        init,
        refs,
        stats,
        action,
        buttons,
        periods,
        filters,
        columns,
        refFilters,
        dashProfit,
        cancelOrders,
        botShortInfo,
        confirmLabel,
        genConfCheck,
        reSetFixOrder,
        declineMessage,
        ordersFilters,
        filtersChanged,
        showConfirmation,
        totalUpdateLoading,
        markLastAsCanceled,
        columnsOrdersTable,
        showConfirmationCheck,
        stopBotAfterOperation,
        router,
        t,
        getAll,
        currency,
        exchange,
        cyclesGet,
        ordersGet,
        updateDate,
        sortColumn,
        totalUpdate,
        doSomething,
        downloadData,
        resetFilters,
        changeFilter,
        getShortInfo,
        onCancelOrders,
        changeFilter2,
        downloadData2,
        changeOrdersFilters,
        doMarkLastAsCanceled,
        onGeneralConfirmClicked,
    };
}